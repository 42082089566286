import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout/Layout'
import styles from '../assets/scss/pages/succes.module.css'

import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Link } from 'gatsby'

const Success = () => (
    <Layout>
        <Helmet>
            <title>Succès de l'envoi du formulaire</title>
            <meta name="description" content="Success Page" />
        </Helmet>
        <div id="succes" className={styles.succes}>
            <section id="one">
                <div className={styles.inner}>
                    <header className="major">
                        <h1>Message envoyé avec succès</h1>
                    </header>
                    <span className={styles.image}>
                        <ThumbUpIcon fontSize={'large'} />
                    </span>
                    <p>Merci de m'avoir contacté ! </p>
                    <Link to={'/'}>
                        <ArrowBackIcon fontSize={'small'} /> Retour à la page
                        d'accueil
                    </Link>
                </div>
            </section>
            {/*<div id="bg"></div>*/}
        </div>
    </Layout>
)

export default Success
